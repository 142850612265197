.App {
  text-align: center;
  padding: 20px;
}

form {
  margin: 20px 0;
}

input[type="text"] {
  padding: 10px;
  width: 300px;
  margin-right: 10px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
}

img {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
}